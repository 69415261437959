import { UserRole } from "@prisma/client";
import { type UserPublic } from "~/types";
import { hashString } from "./crypto";

export function getFullName(firstname: string, lastname: string): string {
  return `${firstname} ${lastname}`;
}

export function getUserFullName(user?: UserPublic | null): string | undefined {
  if (!user) {
    return undefined;
  }
  return `${user?.firstname} ${user?.lastname}`;
}

export function getDisplayName(firstname?: string, lastname?: string): string {
  if (firstname && lastname) {
    return getFullName(firstname, lastname);
  }
  return firstname ?? lastname ?? "";
}

export const isAdmin = (role?: UserRole | null) =>
  role === UserRole.admin || role === UserRole.developer;

export const isCollaborator = (role?: UserRole | null) =>
  role === UserRole.collaborator || role === UserRole.admin;

// Info: use userId as a consistent unique ID and hash it to make it unguessable.
export const getMaskedChannel = (userId: string) => hashString(userId);
