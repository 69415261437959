import { atomWithStorage } from "jotai/utils";

import type {
  Answer,
  ContactData,
  DataInLocalStorage,
  Navigation,
  OnboardingType,
} from "~/modules/onboarding/interfaces";
import { schemaAnswers, schemaNavigation } from "~/modules/onboarding/schemas";
import { type MarketingCampaign } from "~/schema/marketing";
import { type SubmitPendingCasePartial } from "~/schema/pendingCase";
import { StoreKeyName } from "../enums";

export const initialAnswers: Answer[] = [];

export const initialNavigation: Navigation = {
  branchSlug: "case-type",
  branchIteration: 1,
  categorySlug: "case",
  categoryIteration: 1,
};

export const defaultAnswers: DataInLocalStorage<typeof schemaAnswers> = {
  standard: schemaAnswers.parse(initialAnswers),
  patrimonial: schemaAnswers.parse(initialAnswers),
  senior: schemaAnswers.parse(initialAnswers),
  obo: schemaAnswers.parse(initialAnswers),
};

export const defaultNavigation: DataInLocalStorage<typeof schemaNavigation> = {
  standard: schemaNavigation.parse(initialNavigation),
  patrimonial: schemaNavigation.parse(initialNavigation),
  senior: schemaNavigation.parse(initialNavigation),
  obo: schemaNavigation.parse({
    ...initialNavigation,
    categorySlug: "real-estate-patrimony",
    branchSlug: "asset-creation-start",
  }),
};

export const defaultContact: ContactData = {
  firstName: "",
  lastName: "",
  phone: "",
};

export const answersAtom = atomWithStorage<typeof defaultAnswers>(
  StoreKeyName.answers,
  defaultAnswers,
);

export const navigationAtom = atomWithStorage<typeof defaultNavigation>(
  StoreKeyName.navigation,
  defaultNavigation,
);

export const contactAtom = atomWithStorage<ContactData>(
  StoreKeyName.contact,
  defaultContact,
);

export const onboardingTypeAtom = atomWithStorage<OnboardingType>(
  StoreKeyName.onboardingType,
  "standard",
);

export const marketingAtom = atomWithStorage<MarketingCampaign | undefined>(
  StoreKeyName.marketing,
  undefined,
);

const getFromLocalStorage = (
  key: string,
): SubmitPendingCasePartial | undefined => {
  if (typeof window === "undefined") return undefined;

  const storedValue = localStorage.getItem(key);
  if (!storedValue || storedValue === "undefined") return undefined;

  try {
    return JSON.parse(storedValue) as SubmitPendingCasePartial;
  } catch (error) {
    console.error(
      `Warning: Could not parse localStorage data for key "${key}".`,
      error,
    );
    return undefined;
  }
};

export const submitPendingCaseAtom = atomWithStorage<
  SubmitPendingCasePartial | undefined
>(
  StoreKeyName.submitPendingCase,
  getFromLocalStorage(StoreKeyName.submitPendingCase),
);
